<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml">
service_repair.field.hgmc_username.placeholder : "Username ของ HGMC"
service_repair.field.event_detail_rich.placeholder : "เช่น เหตุการณ์หรืออาการ ก่อนที่จะมีปัญหา เป็นต้น"

service_repair.event.is_accident : "เกิดจากอุบัติเหตุ"
service_repair.event.is_accident.not : "ปัญหาทั่วไป"
service_repair.event.date : "เมื่อวันที่ {date}"
service_repair.event.time : "เวลา {time} โดยประมาณ"

service_repair.event.flight_mode : "บินด้วยระบบ"
service_repair.event.hgmc_username : "ใช้ HGMC Username คือ"
</i18n>

<template>
	<div class="service_repair-container">
		<template v-if="canUpdate">
			<div class="service_repair-row">
				<a-checkbox v-model="repairData.is_accident">
					{{$t('service_repair.field.is_accident')}}
				</a-checkbox>
			</div>
			<div class="service_repair-row">
				<div class="service_repair-column column1">
					<label>{{$t('service_repair.field.event_date')}} * : </label>
					<div class="service_repair-input-block">
						<MyDatePicker
							v-model="repairData.event_date"
							:read-only="!repairData.is_accident"
							:disabled="disabled"
							format="DD MMMM YYYY"/>
					</div>
				</div>
				<div class="service_repair-column">
					<label>{{$t('service_repair.field.event_time')}} * : </label>
					<div class="service_repair-input-block">
						<a-time-picker
							v-model="repairData.event_time"
							:disabled="!repairData.is_accident"
							input-read-only
							format="HH:mm"
							value-format="HH:mm"/>
					</div>
				</div>
			</div>
			<div class="service_repair-row">
				<div class="service_repair-column column1">
					<label>{{$t('service_repair.field.flight_mode')}} : </label>
					<div class="service_repair-input-block">
						<a-select
							v-model="repairData.flight_mode"
							:get-popup-container="getPopupContainer" :placeholder="$t('common.unspecify')" :disabled="!repairData.is_accident"
							style="width:150px" allow-clear>
							<a-select-option value="auto">{{$tenum('flight_mode','auto')}}</a-select-option>
							<a-select-option value="manual_gps">{{$tenum('flight_mode','manual_gps')}}</a-select-option>
							<a-select-option value="manual">{{$tenum('flight_mode','manual')}}</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="service_repair-column">
					<label>{{$t('service_repair.field.hgmc_username')}} : </label>
					<div class="service_repair-input-block">
						<a-input v-model="repairData.hgmc_username" :disabled="!repairData.is_accident"
							:placeholder="$t('service_repair.field.hgmc_username.placeholder')"
							style="width:175px"/>
					</div>
				</div>
			</div>
			<div class="service_repair-row">
				<label>{{$t('service_repair.field.event_detail_rich')}} : </label>
				<RichTextInput
					ref="richTextRef"
					v-model="repairData.event_detail_rich" :placeholder="$t('service_repair.field.event_detail_rich.placeholder')"/>
			</div>
		</template>
		<template v-else>
			<div class="service_repair-row">
				<template v-if="repairData.is_accident">
					<p>
						<span class="text-value">{{$t('service_repair.event.is_accident')}}</span>
						<span v-if="$notEmpty(repairData.event_date)">
							{{$t('service_repair.event.date',{ date: $dayjs(repairData.event_date).format("LL") })}}
						</span>
						<span v-if="$notEmpty(repairData.event_time)">
							{{$t('service_repair.event.time',{ time: repairData.event_time })}}
						</span>
					</p>
					<p>
						{{$t('service_repair.event.flight_mode')}}
						<span class="text-value">
							{{$tenum('flight_mode',repairData.flight_mode)}}
						</span>
						{{$t('service_repair.event.hgmc_username')}}
						<span class="text-value">
							{{repairData.hgmc_username|emptyCheck}}
						</span>
					</p>
				</template>
				<p v-else>
					{{$t('service_repair.event.is_accident.not')}}
				</p>
				<label>{{$t('service_repair.field.event_detail_rich')}} : </label>
				<QuillViewer :content="repairData.event_detail_rich"
					:empty-message="$t('common.unspecify')"/>
			</div>
		</template>
	</div>
</template>

<script>
import MyDatePicker from "@components/input/MyDatePicker.vue"
import {Checkbox,TimePicker,Select} from "ant-design-vue"
import RichTextInput from "@components/input/RichTextInput.vue"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
import QuillViewer from "@components/common/QuillViewer.vue"
import PopupMixin from "@mixins/PopupMixin.vue"

function _defaultRepairData() {
	return {
		is_accident : true,
		event_date : null,
		event_time : null,
		flight_mode : undefined,
		hgmc_username : undefined,
		event_detail_rich : undefined,
	}
}
export default {
	components : {
		RichTextInput, MyDatePicker , QuillViewer,
		"a-select" : Select, "a-select-option" : Select.Option ,
		"a-checkbox" : Checkbox ,"a-time-picker" : TimePicker
	} ,
	mixins : [PopupMixin] ,
	props : {
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		disableMode : {
			type: Boolean,
			default: false
		}
	} ,
	data() {
		return {
			repairData : _defaultRepairData()
		}
	} ,
	computed : {
		disabled() {
			return this.disableMode && !this.repairData.is_accident
		}
	} ,
	methods : {
		setData(data) {
			this.repairData = _defaultRepairData()
			if (data) {
				const eventDetail = data.event_detail_rich
				delete data.event_detail_rich
				replaceDeepProperty(this.repairData,data)
				if (data.event_datetime) {
					const date = this.$dayjs(data.event_datetime)
					this.repairData.event_date = date.format('YYYY-MM-DD')
					this.repairData.event_time = date.format('HH:mm')
				}
				if (this.$refs.richTextRef) {
					this.$refs.richTextRef.setContent(eventDetail)
				} else {
					this.repairData.event_detail_rich = eventDetail
				}
			}

		} ,
		getData() {
			const data = copyDeep(this.repairData)
			if (this.$notEmpty(data.event_date) && this.$notEmpty(data.event_time)) {
				data.event_datetime = data.event_date + ' ' + data.event_time+":00"
			} else {
				data.event_datetime = null
			}

			data.flight_mode = data.flight_mode ? data.flight_mode : null
			delete data.event_date
			delete data.event_time
			return data
		} ,
		clear() {
			this.repairData = _defaultRepairData()
		}
	}
}
</script>

<style lang="less" scoped>
.service_repair-row {
	margin-bottom : 12px;
	label {
		color : @info-color;
		margin-right : 2px;
	}
}

.service_repair-input-block {
	display : inline-block;
}
.service_repair-column {
	display : inline-block;
	margin-bottom : 12px;
	&.column1 {
		width : 300px;
	}
	.mobile & {
		display : block;
		width : auto;
	}
}
</style>
