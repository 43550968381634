<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
page.title: "สร้างใบซ่อมนอกสถานที่"
create.btn: "เปิดงานซ่อม"
service.onfield.detail.title: "รายละเอียดงานซ่อม"

service.field.open_by.help: "ระบุผู้รับเรื่องจากลูกค้า"
service.field.open_by.placeholder: "ระบุผู้รับแจ้ง"
service.field.open_date.help: "วันที่เปิดใบซ่อมนอกสถานที่"
service.field.open_time_range.help: "เวลาที่เปิดใบซ่อมนอกสถานที่โดยประมาณ"
service.field.delivery_method.help: "รับเครื่องมาโดยวิธีไหน"
service.field.technician.help: "ช่างที่จะดูแลงานซ่อมนี้"
service.field.technician.placeholder: "ระบุช่าง"
service.field.assistant.help: "ผู้ช่วยช่างที่จะดูแลงานซ่อมนี้"
service.field.assistant.placeholder: "ระบุผู้ช่วยช่าง"

service.field.customer.error: "ข้อมูลลูกค้าไม่ครบ"
service.field.customer.help: "ข้อมูลลูกค้าที่นำส่งเครื่อง หรือ แจ้งปัญหา"
service_form.field.open_type.from_service: "รับมาจากศูนย์ {company} ใบงานเลขที่ {serviceNo}"
service_form.remark: 'การตั้งค่า "@:service.field.is_device_only" จะไม่สามารถแก้ไขได้หลังเปิดใบงานนี้ไปแล้ว'

service.field.is_device_only.help: "ส่งแต่อุปกรณ์อย่างเดียว ไม่จำเป็นต้องส่งโดรนมา เช่น รีโมทเสีย หรือ เครื่องชาร์จเสีย เป็นต้น"
service_task.title: "งานที่ต้องการเข้ารับบริการ"
service_task.title.description: "ถ้าแค่เปลี่ยนอะไหล่ ไม่ต้องระบุงาน (สามารถเพิ่มลดทีหลังได้)"
service_task.repair: "งานซ่อม"
service_task.repair.disabled: "ระบุสำหรับงานซ่อมเท่านั้น"

onfield_create.confirm.title : "ยืนยันการเปิดงานซ่อมนอกสถานที่"
onfield_create.confirm.message : "คุณต้องการยืนยันการเปิดงานซ่อมนอกสถานที่ตามนี้ ?"
onfield_create.success : "เปิดงานซ่อมนอกสถานที่เรียบร้อยด้วย หมายเลขอ้างอิง {service}"
onfield_create.submit.label : "เปิดงานซ่อม"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header :title="$t('page.title')" />
		<div class="input-pane">
			<DroneSearchPanel ref="searchFilterRef" @submit-native-search="handleNativeSearch" />
		</div>
		<a-card v-if="droneDataList" :bordered="false">
			<DroneTable
				bordered
				:show-pixhawk="true"
				:row-key="(record) => record.serialNo"
				:data-source="droneDataList"
				:pagination="pagination"
				:loading="loading"
				:hide-register="true"
				:hide-status="true"
				:hide-firmware="true"
				:hide-phone="false"
				:hide-email="false"
				:is-selectable="true"
				:link-clickable="false"
				@change-drone="handleSelectDrone"/>
			<div v-if="selectedDroneSn">
				<div class="myform-section-title service-detail-title">
					{{ $t('service.onfield.detail.title') }}
				</div>
				<a-form
					ref="formRef"
					layout="horizontal"
					:label-col="labelCol"
					:wrapper-col="wrapperCol"
					:form="formObj"
					@submit.prevent="handleSubmit">
					<a-form-item :label="$t('service.field.open_by')" :extra="$t('service.field.open_by.help')" required>
						<UserCompanySelect
							:placeholder="$t('service.field.open_by.placeholder')"
							:value="formData.open_by_id"
							:company-id="currentCompany.id"
							required
							disabled/>
					</a-form-item>
					<a-form-item :label="$t('service.field.customer')" :extra="$t('service.field.customer.help')" required>
						<ServiceCustomerInput
							v-decorator="[
								'customer',
								{
									initialValue: droneData.owner,
									rules: [{ validator: validateCustomerData }],
								},
							]"
							:drone-id="droneData.id"
							@change="handleCustomerChange"/>
					</a-form-item>
					<a-form-item :label="$t('service.field.open_date')" :extra="$t('service.field.open_date.help')" required>
						<MyDatePicker :value="formData.open_date" :read-only="true" format="DD MMMM YYYY" />
					</a-form-item>
					<a-form-item :label="$t('service.field.open_time_range')" :extra="$t('service.field.open_time_range.help')" required>
						<span>{{ handleOpenTimeRangeDisplay(formData.open_time_range) }}</span>
					</a-form-item>
					<hr />
					<a-form-item :label="$t('service.field.open_type')" required>
						<span class="ant-form-text text-primary">
							{{ $tenum('service.open_type', formData.open_type) }}
						</span>
					</a-form-item>
					<a-form-item :label="$t('service.field.delivery_method')" required>
						<DeliveryDetailInput ref="deliveryDetailRef" :is-onfield="formData.open_type == 'onfield'" />
					</a-form-item>
					<hr />
					<a-form-item :label="$t('service.field.technician')"
						:extra="$t('service.field.technician.help')">
						<UserCompanySelect
							v-decorator="['technician_id']"
							:company-id="currentCompany.id"
							allow-clear
							:placeholder="$t('service.field.technician.placeholder')"
							@change="handleTechnicianChange"/>
					</a-form-item>
					<a-form-item :label="$t('service.field.assistant')"
						:extra="$t('service.field.assistant.help')">
						<UserCompanySelect
							v-decorator="['assistant_id']"
							:company-id="currentCompany.id"
							allow-clear
							:placeholder="$t('service.field.assistant.placeholder')"
							@change="handleAssistantChange"/>
					</a-form-item>
					<hr />
					<a-form-item v-bind="wrapperOnlyLayout" :extra="$t('service.field.is_device_only.help')">
						{{$t('service.label')}}
						<a-checkbox v-model="formData.is_device_only">
							{{ $t('service.field.is_device_only') }}
						</a-checkbox>
					</a-form-item>
					<div class="myform-section-title">
						{{ $t('service_task.title') }}
						<div class="myform-section-description">
							{{ $t('service_task.title.description') }}
						</div>
					</div>
					<div class="service-task-block">
						<a-checkbox v-model="formData.is_repair">
							{{ $t('service_task.repair') }}
						</a-checkbox>

						<BlockPane :block="!formData.is_repair">
							<span slot="message">{{ $t('service_task.repair.disabled') }}</span>
							<ServiceRepairInput ref="serviceRepairRef" :disable-mode="true" class="service_repair-form" :can-update="formData.is_repair" />
						</BlockPane>
					</div>
					<div class="page-action-right">
						<slot name="submitSlot" :formLoading="loading">
							<a-button :loading="loading" type="primary" html-type="submit" class="btn-submit" size="large">
								{{ $t('create.btn') }}
							</a-button>
						</slot>
					</div>
				</a-form>
			</div>
		</a-card>
	</div>
</template>

<script>
import axios from 'axios'
import PageMixin from '@mixins/PageMixin.vue'
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import { emptyDrone } from '@utils/objectUtil'
import ApiError from '@utils/errors/ApiError'
import DroneSearchPanel from '@components/drone/DroneSearchPanel.vue'
import DroneTable from '@components/drone/DroneTable.vue'
import { Checkbox } from 'ant-design-vue'
import UserCompanySelect from '@components/user/UserCompanySelect.vue'
import ServiceCustomerInput from '@components/service/ServiceCustomerInput.vue'
import MyDatePicker from '@components/input/MyDatePicker.vue'
import _get from 'lodash/get'
import { isStringEmpty, timeRange } from '@utils/stringUtil'
import { getEnumSelectOptions } from '@utils/formUtil'
import DeliveryDetailInput from '@components/input/DeliveryDetailInput.vue'
import BlockPane from '@components/common/BlockPane.vue'
import ServiceRepairInput from '@components/service/ServiceRepairInput.vue'

export default {
	components: {
		MyDatePicker,
		DroneSearchPanel,
		ServiceCustomerInput,
		DroneTable,
		'a-checkbox': Checkbox,
		UserCompanySelect,
		DeliveryDetailInput,
		BlockPane,
		ServiceRepairInput,
	},
	mixins: [PageMixin, HasAntdFormMixin, LoginInfoMixin],
	page() {
		const pageTitle = this.$t('page.title')
		return {
			title: pageTitle,
		}
	},
	data() {
		return {
			loading: false,
			droneDataList: null,
			pagination: {
				pageSize: 1000,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			selectedDroneSn: null,
			droneData: emptyDrone(),
			formData: {
				drone_service_drone_instance_id: undefined,
				open_by_id: undefined,
				technician_id : undefined,
				assistant_id : undefined,
				customer_name: '',
				customer_phone: '',
				customer_province: '',
				open_date: undefined,
				open_time_range: undefined,
				open_type: 'onfield',
				delivery_method: 'onfield',
				is_device_only: false,
				is_repair: true,
				repair: {
					event_datetime: undefined,
					event_detail_rich: undefined,
					flight_mode: undefined,
					hgmc_username: undefined,
					is_accident: false,
				},
			},
		}
	},
	computed: {
		timeRangeOptions() {
			const options = ['morning', 'noon', 'afternoon', 'evening']
			return getEnumSelectOptions(this, 'time_range', options)
		},
	},
	mounted() {
		const defaultOpenTime = timeRange(this.$dayjs().hour())

		this.formData = {
			...this.formData,
			open_by_id: this.currentUser.id,
			customer_name: '',
			customer_phone: '',
			customer_province: '',
			open_date: this.$dayjs().format('YYYY-MM-DD'),
			open_time_range: defaultOpenTime,
		}
	},
	methods: {
		callSearchDrone(toPage = null, keyword, searchType) {
			const filter = {
				keyword,
				searchType,
			}
			this.loading = true

			axios
				.get('/api/drones/search', { params: { filter } })
				.then((response) => {
					this.droneDataList = response.data.data.droneInstances;
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
		handleNativeSearch(searchFilter) {
			this.selectedDroneSn = null
			this.callSearchDrone(null, searchFilter.keyword, searchFilter.searchType)
		},
		handleSelectDrone(droneSn) {
			this.selectedDroneSn = droneSn
			const selectedDroneData = this.droneDataList.find((droneData) => droneData.drone.serialNo === droneSn)
			this.droneData = { ...selectedDroneData }
			this.formData = {
				...this.formData,
				customer_name: selectedDroneData.droneOwner.customerId.name,
				customer_phone: selectedDroneData.droneOwner.customerId.phone,
				customer_province: selectedDroneData.droneOwner.customerId.province,
				drone_service_drone_instance_id: selectedDroneData.drone.id,
			}
		},
		handleCustomerChange(value) {
			this.formData = {
				...this.formData,
				customer_name: value.customer_name,
				customer_phone: value.customer_phone,
				customer_province: value.customer_province,
			}
		},
		handleTechnicianChange(value) {
			this.formData = {
				...this.formData,
				technician_id : value,
			}
		},
		handleAssistantChange(value) {
			this.formData = {
				...this.formData,
				assistant_id : value,
			}
		},
		handleSubmit() {
			this.formObj.validateFields((err) => {
				if (!err) {
					this.formData.repair = this.formData.is_repair ? this.$refs.serviceRepairRef.getData() : null
					this.$confirm({
						title: this.$t('onfield_create.confirm.title'),
						content: this.$t('onfield_create.confirm.message'),
						maskClosable: true,
						onOk: () => {
							this.loading = true
							const createServiceForm = {
								customer_name: this.formData.customer_name,
								customer_phone: this.formData.customer_phone,
								customer_province: this.formData.customer_province,
								delivery_method: this.formData.delivery_method,
								drone_service_drone_instance_id: this.formData.drone_service_drone_instance_id,
								is_device_only: this.formData.is_device_only,
								open_by_id: this.formData.open_by_id,
								open_date: this.formData.open_date,
								open_time_range: this.formData.open_time_range,
								open_type: this.formData.open_type,
								assistant_id: this.formData.assistant_id,
								technician_id: this.formData.technician_id,
								delivery_json: '{}'
							}
							axios
								.post('/api/services/create', { service: createServiceForm, repair: this.formData.repair })
								.then((response) => {
									const service = response.data.data.service
									this.$message.success(this.$t('onfield_create.success', { service: service.service_no }))
									this.$router.push({ name: 'onfield/bind_ticket', params: { id: service.id } })
								})
								.catch((error) => {
									this.formSubmitErrors(error)
								})
								.finally(() => {
									this.loading = false
								})
						},
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		},
		validateCustomerData(rule, value, callback) {
			const fail =
				isStringEmpty(_get(value, 'customer_name')) ||
				isStringEmpty(_get(value, 'customer_phone')) ||
				isStringEmpty(_get(value, 'customer_province'))
			if (fail) {
				callback(this.$t('service.field.customer.error'))
			} else {
				callback()
			}
		},
		handleOpenTimeRangeDisplay(value){
			const result = this.timeRangeOptions.find(timeRangeOption => timeRangeOption.value === value)
			if(!result) return ''

			return result.label
		}
	},
}
</script>

<style lang="less" scoped>
.service-task-block {
	margin-bottom: 16px;
	margin-left: calc(20% / 2);
}
.page-action-right {
	float: right;
	margin-right: 30px;
	margin-bottom : 8px;
}
.input-pane {
	padding: 12px 16px;
	border: 1px solid @white;
	border-radius: @border-radius-base;
	box-shadow: @shadow-1-right;
	background: @white;
	margin-bottom: 16px;
	> label {
		line-height: 32px;
		vertical-align: middle;
		margin-right: 2px;
		color: @info-color;
	}
}

.service-detail-title {
	margin-top: 15px;
}
.service_repair-form {
	padding: 16px 32px;
}
</style>
