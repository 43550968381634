<i18n locale="th" lang="yaml" >
delivery.out.detail : "รายละเอียดการส่งเพิ่มเติม"
delivery.out.shipping.shipping_agent.label : "ส่งผ่าน"
delivery.out.shipping.reference_no.label : "หมายเลขจัดส่ง"
delivery.out.self_shipping.shipping_by.label : "ส่งโดย"
delivery.out.self_shipping.receive_date.label : "ถึงลูกค้าวันที่"

delivery.out.shipping.shipping_agent.placeholder : "เช่น ไปรษณีย์,Kerry"
delivery.out.shipping.reference_no.placeholder : "หมายเลขขนส่ง"
delivery.out.self_shipping.shipping_by.placeholder : "ชื่อคนเอาไปส่ง"

delivery.in.detail : "รายละเอียดการรับเครื่องเพิ่มเติม"
delivery.in.self_shipping.request_date.label : "วันที่ลูกค้าแจ้ง"
delivery.in.self_shipping.shipping_by.label : "ไปรับโดย"
delivery.in.service_shipping.shipping_by.label : "ส่งมาโดย"

delivery.in.self_shipping.shipping_by.placeholder : "ชื่อคนเข้าไปรับ"
delivery.in.service_shipping.shipping_by.placeholder : "ชื่อคนมาส่ง"
</i18n>

<template>
	<div class="delivery-input-container">
		<template v-if="isOnfield">
			<span class="ant-form-text text-primary">
				{{$tenum('service.open_type','onfield')}}
			</span>
		</template>
		<template v-else>
			<div class="delivery-type">
				<a-radio-group v-model="type" :options="typeOptions" :disabled="disabled"/>
			</div>
		</template>
		<template v-if="isSendOut">
			<template v-if="type == 'shipping'">
				<div class="delivery-detail-title">
					{{$t('delivery.out.detail')}}
				</div>
				<div class="delivery-detail">
					<div class="delivery-col">
						<label>{{$t('delivery.out.shipping.shipping_agent.label')}}</label>
						<div class="delivery-input">
							<a-input v-model="shippingData.shipping_agent"
								:disabled="disabled"
								style="width:150px;"
								:placeholder="$t('delivery.out.shipping.shipping_agent.placeholder')"/>
						</div>
					</div>
					<div class="delivery-col">
						<label>{{$t('delivery.out.shipping.reference_no.label')}}</label>
						<div class="delivery-input">
							<a-input v-model="shippingData.reference_no"
								:disabled="disabled"
								style="width:150px;"
								:placeholder="$t('delivery.out.shipping.reference_no.placeholder')"/>
						</div>
					</div>
				</div>
			</template>

			<template v-if="type == 'self_shipping'">
				<div class="delivery-detail-title">
					{{$t('delivery.out.detail')}}
				</div>
				<div class="delivery-detail">
					<div class="delivery-col">
						<label>{{$t('delivery.out.self_shipping.shipping_by.label')}}</label>
						<div class="delivery-input">
							<a-input v-model="selfShippingData.shipping_by"
								:disabled="disabled"
								style="width:200px;"
								:placeholder="$t('delivery.out.self_shipping.shipping_by.placeholder')"/>
						</div>
					</div>
					<div class="delivery-col">
						<label>{{$t('delivery.out.self_shipping.receive_date.label')}}</label>
						<div class="delivery-input">
							<MyDatePicker
								v-model="selfShippingData.receive_date"
								format="DD MMMM YYYY"
								:read-only="disabled"/>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<template v-if="type == 'self_shipping'">
				<div class="delivery-detail-title">
					{{$t('delivery.in.detail')}}
				</div>
				<div class="delivery-detail">
					<div class="delivery-col">
						<label>{{$t('delivery.in.self_shipping.request_date.label')}}</label>
						<div class="delivery-input">
							<MyDatePicker
								v-model="selfShippingData.request_date"
								format="DD MMMM YYYY"
								:read-only="disabled"/>
						</div>
					</div>
					<div class="delivery-col">
						<label>{{$t('delivery.in.self_shipping.shipping_by.label')}}</label>
						<div class="delivery-input">
							<a-input v-model="selfShippingData.shipping_by"
								:disabled="disabled"
								style="width:200px;"
								:placeholder="$t('delivery.in.self_shipping.shipping_by.placeholder')"/>
						</div>
					</div>
				</div>
			</template>
			<template v-if="type == 'service_shipping'">
				<div class="delivery-detail-title">
					{{$t('delivery.in.detail')}}
				</div>
				<div class="delivery-detail">
					<div class="delivery-col">
						<label>{{$t('delivery.in.service_shipping.shipping_by.label')}}</label>
						<div class="delivery-input">
							<a-input v-model="serviceShippingData.shipping_by"
								:disabled="disabled"
								style="width:200px;"
								:placeholder="$t('delivery.in.service_shipping.shipping_by.placeholder')"/>
						</div>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import {Radio} from "ant-design-vue"
import {replaceDeepProperty} from "@utils/objectUtil"
import {getEnumSelectOptions} from "@utils/formUtil"
import MyDatePicker from "@components/input/MyDatePicker.vue"
function _defaultShipping(isSendOut) {
	if (isSendOut) {
		return {
			shipping_agent : undefined,
			reference_no : undefined,
		}
	} else {
		return {}
	}
}
function _defaultSelfShipping(isSendOut) {
	if (isSendOut) {
		return {
			shipping_by : undefined,
			receive_date : undefined,
		}
	} else {
		return {
			request_date : undefined,
			shipping_by : undefined,
		}
	}
}
function _defaultServiceShipping() {
	return {
		shipping_by : undefined,
	}
}

export default {
	components : {
		"a-radio-group" : Radio.Group , MyDatePicker,
	} ,
	props : {
		disabled : {
			type : Boolean,
			default : false,
		} ,
		isCustomer : {
			type : Boolean,
			default : true,
		} ,
		isSendOut : {
			type : Boolean,
			default : false
		} ,
		isOnfield:{
			type: Boolean,
			default: false
		}
	}	,
	data() {
		return {
			type : undefined,
			shippingData : _defaultShipping(false) ,
			selfShippingData : _defaultSelfShipping(false) ,
			serviceShippingData : _defaultServiceShipping()
		}
	} ,
	computed : {
		typeOptions() {
			const options = []
			if (this.isCustomer) {
				options.push('walkin','shipping','self_shipping')
			} else {
				options.push('shipping')
				if (this.isSendOut) {
					options.push('self_shipping')
				} else {
					options.push('service_shipping')
				}
			}
			const enumName = this.isSendOut ? "delivery.type.out" : 'delivery.type'
			return getEnumSelectOptions(this,enumName,options)
		} ,
	} ,
	watch : {
		isCustomer() {
			this.validateType()
		} ,
	} ,
	methods : {
		validateType() {
			if (!this.$notEmpty(this.type)) {
				this.type = undefined
			} else {
				const idx = this.typeOptions.findIndex((t)=> t.value == this.type)
				if (idx < 0) {
					this.type = undefined
				}
			}
		} ,
		getData() {
			const rtn = {
				type : this.type ,
			}
			switch(this.type) {
				case 'shipping' :
					rtn.data = {...this.shippingData}
					break;
				case 'self_shipping' :
					rtn.data = {...this.selfShippingData}
					break;
				case 'service_shipping' :
					rtn.data = {...this.serviceShippingData}
					break;
				default :
					rtn.data = {}
					break;
			}
			return rtn;
		} ,
		setData(type,data) {
			this.type = type;
			this.validateType()
			this.shippingData = _defaultShipping(this.isSendOut)
			this.selfShippingData = _defaultSelfShipping(this.isSendOut)
			this.serviceShippingData = _defaultServiceShipping()
			if (data) {
				switch(this.type) {
					case 'shipping' :
						replaceDeepProperty(this.shippingData,data)
						break;
					case 'self_shipping' :
						replaceDeepProperty(this.selfShippingData,data)
						break;
					case 'service_shipping' :
						replaceDeepProperty(this.serviceShippingData,data)
						break;
				}
			}
		}

	}
}
</script>

<style lang="less" scoped>
.delivery-detail {
	margin-top : 4px;
	display : flex;
	flex-wrap: wrap;
	.delivery-col {
		margin : 0 16px 8px 0;
	}
	label {
		color : @info-color;
		margin-right : 2px;
		&::after {
			content : ' :'
		}
	}
	.delivery-input {
		display : inline-block;
		.mobile & {
			display : block;
		}
	}
}

.delivery-detail-title {
	text-decoration: underline;
	color : @primary-color;
	margin-bottom : 4px;
	width : 100%;
}
.delivery-type {
	margin-bottom : 12px;
}

</style>
